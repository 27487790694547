###
	main.coffee - this file is part of Scann, a shopping web app

	Copyright 2017 Joshua Stutter <josh@picosites.co.uk>
###

showLoading = (o, p = {}) ->
	setTimeout ->
		if o
			$('.ui-loader-background').show()
			$.mobile.loading 'show', p
		else
			$('.ui-loader-background').hide()
			$.mobile.loading 'hide'
	, 1
rm = (a, i) ->
	x for x in a when x isnt i
#taken from gist.github.com/jamesflorentino/2716003
class Cookie
	constructor: (@days = null) ->
	set: (name, value, days = @days) =>
		if days is null
			date = new Date()
			date.setTime date.getTime + (days * 86400000)
			expires = "; expires=" + date.toGMTString()
		else
			expires = ""
		document.cookie = name + "=" + value + expires + "; path=/"
	get: (name) ->
		nameEQ = name + "="
		for c in document.cookie.split ';'
			c = c.substring(1, c.length) while c.charAt(0) is " "
			if c.indexOf(nameEQ) is 0
				return c.substring(nameEQ.length, c.length)
		null
	delete: (name) =>
		@setCookie name, "", -1
class BarcodeReader
	constructor: (@basket) ->
		@nextdetect = 0
		@nextscan = 0
		@debounce = 500
		@scantime = 2000
		@prompttime = 60000
		@linecolor = 'red'
		@linewidth = 5
		@scantimeoutid = null
		@enabled = false
		@timeoutid = undefined
		@torchon = false
		@beepsnd = new Audio('data:audio/wav;base64,UklGRtIGAABXQVZFZm1\
0IBAAAAABAAEAQB8AAEAfAAABAAgAZGF0Ya0GAACAf4CAgICAgH+AgX1/f31/gX1/gX6Ag35+hH5/gY\
F7j2STlT6+fka/gFGmmkmboUmWoEaZpTStoiPCmh3NlCPJkDC6kza0lDaxlDiymjG4minClSnIkCvIi\
y7CjTG9jTW8jDm8jDu8jDm9jje+jTXAijbBhzjBhjvBhz2+hkC8hj++hTy/hTrBgjrCgTnDgjzCgjzB\
gD/Bf0G/fUDCfT7FfD7HfD/IfUDIfUHGfEDFe0DHeT/GekLGeUPFeUPFd0PFdELHdULJckPJb0fJbEz\
Ia07Fa03GbEzIbEvJakzJaU7MaFDKZlTKZFPIYlTIZFPKY1LMZFPMZFXLYVbKYVfIYFfIYFbJX1fKXl\
nLXVnLXFnJWlnHWVrIWlvKWlzKWl7LW17JWF7JWF/LWGDKWWDLV2HKV2HJV2LKVmPIVWPIVWLHVGPHU\
2bHVGjGVGjGVGfEUmfEUWfET2rFUGzGUW3GT2vDTmzETWzDTnDFUHHETnLETXHCTHLAS3LBTnTBTXPA\
SnS/S3W+THa/S3W+SXfBSna/SnfASXe+R3m/Sny/SX28Rny9R3u8RX29R369RoC9R3+7Rny5RYC7RX+\
6R4O4RIK4RYK4RoO3RIG5RYO5Q4O6RYW3QIS2RIa0RYezR4e0RYa1Q4i0Q4q2Qoq0QouzQoyzQ4u1Q4\
u1Q42xQoywQY6uQY+vQ46vQY6vQZGsP5GrPpOtP5KtQZKuQZKtPZauPpWsPpWsQJirP5qrQJmqPpeqP\
5iqQJesPpmqPZuoPZulQZukP5qmPpykPZulPaGgQZqnOqWSU59tj3Z1kXh7gYtzg4R6hH2Ah3x6ioJx\
kIBxjoJ2iIV4hYZ2hoN6hIN6goN5hIF5g396hYB5hIB4hoB5g4F4hIN5hIN6goN7goN7g4R6hYN6hYJ\
7hYJ5hYJ7hYN8hYJ7hoN8hYN6hYN7hYN7hYJ8hoN8hoJ7hYJ7hoF6hoF6hYF6hoJ7hYF6hYB6hYB6hX\
95hIF5hH94hIB5hIB5g4B6hIB5hH96g396g396hXx/gHyCfn6Df32Efn2Ef36Efn6Ef32Ef3uGfnyFf\
nyGf32Ffn2Ff32Ef32Efn2Ffn6Efn2Ef32FfnyFf32Efn2EfX2EfnyEf32Efn6Ff32Efn6Efn6Ffn2F\
f36Ffn6Efn6Efn6Ff3+Efn2Ffn6Efn6Efn6Efn6Efn6EfX6FfX6EfX+FfH+FfX+EfX+EfH+FfX+FfYC\
FfH6EfX6FfH+EfX6FfH6FfX+FfX+DfH+FfH+EfH+EfH+FfX+FfX+FfH+EfICDfX+EfX+EfX+FfH+FfX\
+EfH+EfICEfYCEfICFfICEe3+EfICEfYCEfICEfICDfICEfH+EfICEfIKCfIOAfYKBfIKBfIKAfYKAf\
oGBfYGBfoKBfoKBfoKAfoKBfoKBfoGBf4GBfoGBfoKBfoKBfoGBfoGAfoGAfoGBfoGBfoGBfoCAf4GA\
foKBfoGAfoGAf4GBfoGAfoGAfoGAf4GAf4GAf4GAf4GBf4GAf4GBf4CBf4CBfoCAf4GAfoGAf4GBf4K\
Bf4GAf4GAf4F/foGAf4KAfoGBf4KAf4GAf4GAfoGAfoF/f4GAf4F/foGAfoJ/foJ/f4F/foF/f4J/fo\
GAf4GAf4F/foKAfoGAf4GAfoGAf4J/foGAf4F/f4J/f4KAf4KAgIKAf4GAgIF/f4KAfoJ/f4J/gIF/f\
4F/f4F/f4KAf4F/f4KAgIGAf4F/foKAf4J/f4KAf4F/gIF/gIGAf4GAgIF/f4KAgIB+f4F/f4CAf4J/\
gIF+gYF+gIF+gH+Agn6AgX+AgX9/gn6AgX6AgX6Agn+BgX+BgYCAgX+AgX+AgX+Agn+AgH+AgH+AgIC\
AgX+BgYCAgX+AgICAgYCBgX+AgX+AgX+BgICAgH+AgX+AgX+BgX5/gX+AgX+AgX6AgH+BgH+AgX+AgX\
6CgH+BgYCBgH+BgX+BgH+BgH6BgX+Af3+BgX+BgH+BgH+BgX+BgH+AgH+BgH+AgH+BgH+BgH+BgH+Ag\
H+Bf3+BgX+BgYCBgH+BgH+AgH+Bf3+BgH+BgX+BgH+BgICBgX+BgYCBgX+BgH+AgICAgH+BgX+Af3+A\
gH+BgICBf4CAgICAgICAf3+AgH+BgH+Bf3+AgICAf4B/gYCAgX+AgH+AgICBgH+AgICAgICAgICAgIC\
Af4CBgICAgYCAf4CAgIGBgICAgIB/gICAgIB/gH+AgH+AgICAf4CAgICAgH+AgIB/gICAgICAgH+AgX\
+AgICAgICAf4CAgICAf3+AgIGAgAA=')
		@state = {
			inputStream: {
				type: 'LiveStream',
				constraints: {facingMode: 'environment'}
			},
			locator: {
				patchSize: 'x-large',
				halfSample: true
			},
			numOfWorkers: 4,
			decoder: {
				readers: [
					{
						format: 'ean_8_reader',
						config: {}
					},
					{
						format: 'ean_reader',
						config: {}
					},
					{
						format: 'upc_reader',
						config: {}
					}
				]
			},
			locate: true
		}
	checkCapabilities: ->
		track = Quagga.CameraAccess.getActiveTrack()
		capabilities = {}
		if typeof track.getCapabilities is 'function'
			capabilities = track.getCapabilities()
			if capabilities.torch?
				$('#interactive').off 'click'
					.click =>
						@torchon = not @torchon
						track.applyConstraints {
							advanced: [{
								torch: @torchon
							}]
						}
						.catch(e -> console.log e)
	pause: =>
		try
			Quagga.stop()
		$('#interactive').removeClass 'active'
		@clear_timeout()
		console.log 'barcode reader paused'
	start: =>
		Quagga.init @state, (err) =>
			if err
				console.log err
				return
			@enabled = true
			Quagga.start()
			$('#interactive').addClass 'active'
			@checkCapabilities()
			Quagga.onProcessed (result) =>
				if @scantimeoutid is null
					@scantimeoutid = setTimeout =>
						@lastResult = null
					, @scantime
				drawingCtx = Quagga.canvas.ctx.overlay
				drawingCanvas = Quagga.canvas.dom.overlay
				w = parseInt drawingCanvas.getAttribute 'width'
				h = parseInt drawingCanvas.getAttribute 'height'
				drawingCtx.clearRect 0, 0, w, h
				cr = result? and result.codeResult?
				rcrc = cr and result.codeResult.code?
				if rcrc
					Quagga.ImageDebug.drawPath result.line,
						{x: 'x', y: 'y'}, drawingCtx,
						{
							color: @linecolor,
							lineWidth: @linewidth
						}
				Quagga.onDetected (result) =>
					if not @enabled
						return
					@reset_timeout()
					if @scantimeoutid isnt null
						clearTimeout @scantimeoutid
						@scantimeoutid = null
					code = result.codeResult.code
					tdate = (new Date()).getTime()
					if tdate < @nextscan
						return
					if @lastResult isnt code
						@lastResult = code
						@nextdetect = tdate + @debounce
						return
					if @nextdetect < tdate
						@nextdetect = tdate + @scantime
						@nextscan = @nextdetect
						@lastResult = null
						console.log code
						@beepsnd.play()
						@basket.process_item code
			console.log 'barcode reader started'
			@save_cpu()
	clear_timeout: =>
		if @timeoutid
			clearTimeout @timeoutid
			@timeoutid = undefined
	reset_timeout: =>
		@clear_timeout()
		@save_cpu()
	open_prompt: ->
		popup_open $('#save-diag')
	save_cpu: =>
		@timeoutid = setTimeout =>
			@pause()
			@open_prompt()
		, @prompttime
show_error = (str) ->
	$("[data-role='popup']").one 'popupafterclose', ->
		$('#err-message').html str
		popup_open $('#err-diag')
	try
		$("[data-role='popup']").popup 'close'
		popup_open $('#err-diag')
class Basket
	constructor: (@basketlist) ->
		@data = {}
		@total = '0.00'
		@crc = 0
		@lastcode = undefined
		@popuptime = 2000
		@store = undefined
		@loggedin = false
		@api = 'http://localhost:8080/server/public/index.php/'
		@api_lock = false
		@cookie = new Cookie()
		@token = @cookie.get 'token'
		if @token is null
			@token = ''
		@make_api_call '', true, {}, (dat) =>
			if dat.store?
				$('#continue-transaction-store').html dat.store
				popup_open $('#continue-transaction-diag')
			for code, item of @data
				@add_item code
	make_api_call: (action, load, input, callback = ((dat) ->)) =>
		if @api_lock
			console.log 'API is locked'
			return
		@api_lock = true
		input['action'] = action
		$.ajax {
			method: "POST",
			url: @api + @token,
			success: (dat, stat, jqxhr) =>
				showLoading false
				if dat.loggedin?
					@loggedin = dat.loggedin
				if dat.loggedin? and dat.loggedin is true
					$('#logout-btn').removeClass 'ui-disabl\
ed'
				if dat.store?
					@store = dat.store
					$('#store-name-scan').html dat.store
				else
					$('#logout-btn').addClass 'ui-disabled'
				if dat.token?
					@token = dat.token
					@cookie.set 'token', dat.token
				if dat.success is true
					if dat.data?
						@data = dat.data
					if dat.total?
						@total = dat.total
					callback dat
				else
					console.log 'There was an error for API\
 request that received token ' + dat.token + '.'
					show_error dat.textresponse
			,
			beforeSend: ->
				if load
					showLoading true
			,
			contentType: 'application/json',
			data: JSON.stringify(input),
			dataType: 'json',
			error: (jqxhr, stat, err) ->
				showLoading false
				show_error err
			,
			complete: =>
				@api_lock = false
		}
	process_item: (code) =>
		exists = (@data[code])?
		@make_api_call 'add', true, {'code': code, 'qty': 1}, (dat) =>
			if exists
				@update_item code
			else
				@add_item code
			t = @data[code].name + ' (£' + @data[code].price + ')'
			$('#basket-popup-code').html t
			$('#basket-popup').popup 'open', {
				transition: 'pop',
				positionTo: $('#basket-popup-label')
			}
			$('#basket-to-pay').removeClass 'ui-disabled'
			setTimeout ->
				$('#basket-popup').popup 'close'
			, @popuptime
	reprocess_basket: =>
		$(@basketlist).html '<div>Basket is empty</div>'
		for code, dat of @data
			@add_item code
	add_item: (code) =>
		if (Object.keys @data).length <= 1
			$(@basketlist).empty()
		n = @data[code].name
		html = '<li id="basket-item-' + code + '" data-role="collapsibl\
e" data-corners="false" data-shadow="false" data-iconshadow="true" data-iconpos\
="right" data-inset="false"><h2><span class="basket-item-name">' + n + '</span>\
<span class="ui-li-count">' + @data[code].qty + '</span><span class="ui-li-asid\
e">£' + @data[code].price + '</span></h2><div class="ui-content"><ul data-role=\
"listview"><li><a href="#delete-diag" class="ui-btn ui-icon-delete ui-red delet\
e-diag-btn" data-code="' + code + '" data-transition="pop" data-rel="popup">Rem\
ove</a></li><li><a href="#qty-diag" class="ui-btn ui-icon-plus ui-green qty-dia\
g-btn" data-code="' + code + '" data-transition="pop" data-rel="popup">Change Q\
TY</a></li></ul></li>'
		$(@basketlist).append $(html)
		$('#basket-item-' + code).enhanceWithin().collapsible()
		$(@basketlist).listview 'refresh'
		@lastcode = code
		@update_total()
	update_item: (code) =>
		n = @data[code].name
		$('#basket-item-' + code + ' .basket-item-name').text n
		$('#basket-item-' + code + ' .ui-li-count').text @data[code].qty
		p = parseFloat(@data[code].price) * (@data[code].qty)
		t = '£' + p.toFixed 2
		$('#basket-item-' + code + ' .ui-li-aside').text t
		@lastcode = code
		@update_total()
	is_empty: =>
		Object.keys(@data).length is 0
	remove_item: (code) =>
		@make_api_call 'remove', true, {
			'code': code,
			'qty': @data[code].qty
		}, (dat) =>
			$('#basket-item-' + code).remove()
			@update_total()
			if @is_empty()
				$('#basket-to-pay').addClass 'ui-disabled'
	update_total: =>
		$('.shopping-total').html '£' + @total
	set_qty: (code, qty) =>
		if qty isnt @data[code].qty
			if qty > @data[code].qty
				@make_api_call 'add', true, {
					'code': code,
					'qty': qty - @data[code].qty
				}, (dat) =>
					@update_item code
			else if qty < @data[code].qty
				@make_api_call 'remove', true, {
					'code': code,
					'qty': @data[code].qty - qty
				}, (dat) =>
					@update_item code
			@lastcode = code
	get_last: =>
		@data[@lastcode]
	set_last: (code) =>
		@lastcode = code
	populate_transactions: (saved, previous, callback = (->)) ->
		el = $('#saved-transactions-ul')
		html = ''
		inloop = false
		for b in saved
			inloop = true
			m = moment.unix b.time
			t = m.fromNow()
			t = t.charAt(0).toUpperCase() + t.slice 1
			html += '<li data-role="collapsible" data-iconpos="righ\
t" data-inset="false" data-mini="true"><h3>' + b.name + '</h3><div class="ui-co\
ntent"><p>' + t + ' - <strong>£' + b.total + '</strong></p><div data-role="cont\
rolgroup" style="text-align:center;" data-type="horizontal"><a class="ui-btn ui\
-shadow ui-icon-edit ui-btn-icon-left ui-mini load-transaction-btn" data-transa\
ctionid="' + b.id + '">Load</a><a class="ui-btn ui-shadow ui-icon-delete ui-btn\
-icon-right delete-transaction-btn ui-mini" data-transactionid="' + b.id + '">D\
elete</a></div><ul data-role="listview">'
			for c, i of b.data
				p = (parseFloat i.price).toFixed 2
				html += '<li data-code="' + c + '"><h2 style="m\
argin-right: 80px;">' + i.name + '</h2><span class="ui-li-aside">£' + p + '</sp\
an><span class="ui-li-count">' + i.qty + '</li>'
			html += '</ul></div></li>'
		if not inloop
			html = '<p>No saved transactions</p>'
		el.html html
		el.enhanceWithin()
		el.listview 'refresh'
		el = $('#previous-transactions-ul')
		html = ''
		p = moment().add 1, 'days'
		previous.sort (a, b) ->
			a.time - b.time
		inloop = false
		for b, i in previous
			inloop = true
			m = moment.unix b.time
			if m.isBefore p, 'days'
				count = 0
				for j in [i...previous.length]
					n = moment.unix previous[j].timeoutid
					if n.isSame m, 'days'
						count++
					else
						break
				html += '<li data-role="list-divider" data-mini\
="true">' + m.format 'dddd, D MMM Y' + '<span class="ui-li-count">' + count + '\
</span></li>'
			html += '<li data-role="collapsible" data-iconpos="righ\
t" data-inset="false" data-mini="true"><h3>' + b.store + '</h3><div class="ui-c\
ontent"><ul data-role="listview">'
			for c, i of b.data
				p = (parseFloat i.price).toFixed 2
				html += '<li data-code="' + c + '"><h2 style="m\
argin-right: 80px;">' + i.name + '</h2><span class="ui-li-aside">£' + p + '</sp\
an><span class="ui-li-count">' + i .qty + '</li>'
			html += '</ul></div></li>'
			p = moment.unix b.time
		if not inloop
			html = '<p>No previous transactions</p>'
		el.html html
		el.enhanceWithin()
		el.listview 'refresh'
		callback()
$(document).ready ->
	$('#menu').enhanceWithin().panel()
	$("body > [data-role='popup']").enhanceWithin().popup()
	$("[data-role='header']").toolbar()
	basket = new Basket '#basket-list'
	reader = new BarcodeReader basket
	cookie = new Cookie()
	m = $("[data-role='popup']").not '#basket-popup'
	m.on 'popupbeforeposition', ->
		reader.pause()
		$(this).find 'input'
			.val ''
	$('.startreader-btn').click ->
		reader.start()
	$('.pausereader-btn').click ->
		reader.pause()
	$('.storeselect-btn').click ->
		$(this).siblings().removeClass 'ui-btn-active'
		$(this).addClass 'ui-btn-active'
		basket.store = $(this).data 'store'
		basket.make_api_call 'store', true, {'store': basket.store}
	$('#home').on 'pageinit', ->
		if basket.store
			active = $('.storeselect-btn[data-store="' + basket.store + '"]')
			active.addClass 'ui-btn-active'
			active.siblings().removeClass 'ui-btn-active'
		else
			$('.storeselect-btn').removeClass 'ui-btn-active'
	$('#basket-list').on 'click', '.delete-diag-btn', ->
		basket.set_last ($(this).data 'code')
	$('#basket-list').on 'click', '.qty-diag-btn', ->
		console.log 'setting code'
		basket.set_last ($(this).data 'code')
	$('#delete-item-btn').click ->
		basket.remove_item basket.lastcode
	$('#qty-diag').on 'popupbeforeposition', (e, ui) ->
		console.log 'opening qtydiag'
		$('#qty-text').val basket.get_last().qty
	$('#qty-diag-confirm-btn').click ->
		basket.set_qty basket.lastcode, $('#qty-text').val()
		basket.update_item basket.lastcode
	$('#qty-text').keyup ->
		@value = @value.replace /[^0-9]/g, ''
		if parseInt @value < 1
			@value = 1
	$('#qty-fewer').click ->
		v = (parseInt $('#qty-text').val()) - 1
		if v < 1
			v = 1
		if v is 1
			$(this).addClass 'ui-disabled'
		$('#qty-text').val v
	$('#qty-more').click ->
		v = (parseInt $('#qty-text').val()) + 1
		$('#qty-text').val v
		$('#qty-fewer').removeClass 'ui-disabled'
	$(document).on 'tabsbeforeactivate', '#app-tabs', (e, ui) ->
		classes = 'in slide '
		if ui.newPanel.index() < ui.oldPanel.index()
			classes += ' reverse'
		$(ui.newPanel)
			.addClass classes
			.one 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd\
 oanimationend animationend'
			, ->
				$(this).removeClass classes
		classes = 'out slide '
		if ui.newPanel.index() >= ui.oldPanel.index()
			classes += ' reverse'
		$(ui.oldPanel)
			.addClass classes
			.one 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd\
 oanimationend animationend'
			, ->
				$(this).removeClass classes
		if ui.newPanel[0].id isnt 'scan'
			reader.pause()
		else
			reader.start()
	$('#signin-username-input, #signin-password-input').on 'keyup', ->
		a = not $('#signin-username-input').val()
		b = not $('#signin-password-input').val()
		if a or b
			$('#login-account-btn').addClass 'ui-disabled'
		else
			$('#login-account-btn').removeClass 'ui-disabled'
	$('#app').on 'pageinit', ->
		$('#app-tabs').tabs 'option', 'active', 1
		$('#scan-btn').trigger 'click'
		$('#app').off 'pageinit'
		$('#login-diag').on 'popupbeforeposition', ->
			$(this).find 'input'
				.val ''
		$('#login-diag').on 'popupafterclose', ->
			if not basket.loggedin
				$('#app-tabs').tabs 'option', 'active', 0
				$('#basket-btn').trigger 'click'
			else
				$('#app-tabs').tabs 'option', 'active', 2
				$('#pay-btn').trigger 'click'
	$(document).on 'pageshow', (e, ui) ->
		if (ui.toPage.attr 'id') isnt 'app'
			reader.pause()
			active = $('a[data-store="' + basket.store + '"]')
			active.addClass 'ui-btn-active'
			active.siblings().removeClass 'ui-btn-active'
		else
			alter_scan_offset()
			if basket.store is undefined
				$.mobile.navigate '#', {transition: 'slide'}
				reader.pause()
			else
				reader.start()
	$('#save-diag').on 'popupafterclose', (e, ui) ->
		reader.start()
	$('#basket-to-pay').click ->
		if basket.loggedin
			$('#app-tabs').tabs 'option', 'active', 2
			$('#pay-btn').trigger 'click'
		else
			popup_open $('#login-diag')
	$('#pay-btn').click ->
		if basket.loggedin
			$('#app-tabs').tabs 'option', 'active', 2
			$('#pay-btn').trigger 'click'
		else
			popup_open $('#login-diag')
	$('#create-account-btn').click ->
		basket.make_api_call 'create-account', true, {
			'username': $('#create-username-input').val(),
			'password': $('#create-password-input').val(),
			'email': $('#email-two-input').val()
		}, (dat) ->
			$('#login-diag').one 'popupafterclose', ->
				$('#info-diag-text').html 'Thank you. An activa\
tion code has been sent to ' + $('#email-two-input').val() + '. Click the link \
in the e-mail to activate your account.'
				popup_open $('#info-diag')
			$('#login-diag').popup 'close'
	$('#login-account-btn').click ->
		basket.make_api_call 'login', true, {
			'username': $('#signin-username-input').val(),
			'password': $('#signin-password-input').val()
		}, (dat) ->
			if basket.loggedin
				$('#login-diag').popup 'close'
			else
				$('#login-account-msg').html dat.textresponse
	$('#goto-forgot-password-btn').click ->
		$('#login-diag').one 'popupafterclose', ->
			popup_open $('#forgot-password-diag')
		$('#login-diag').popup 'close'
	$('#forgot-password-btn').click ->
		basket.make_api_call 'forgotpassword', true, {
			'username': $('#forgot-password-username-input').val()
		}, (dat) ->
			$('#forgot-password-diag').one 'popupafterclose', ->
				$('#info-diag-text').html 'Your password has be\
en reset and a password reset code has been sent to ' + dat.email + '. Click th\
e link in the e-mail to reactivate your account.'
				popup_open $('#info-diag')
			$('#forgot-password-diag').popup 'close'
	alter_scan_offset()
	$(window).resize ->
		alter_scan_offset()
	$('#find-listview').on 'filterablebeforefilter', (e, data) ->
		ul = $(this)
		input = $(data.input)
		value = input.val()
		html = ""
		ul.html ''
		if value and value.length > 2
			ul.html "<li><div class='ui-loader'><span class='ui-ico\
n ui-icon-loading'></span></div></li>"
			ul.listview 'refresh'
			basket.make_api_call 'search', false, {'input': value}
			, (dat) ->
				$.each dat.results, (i, val) ->
					html += '<li><a data-code="'
					html += val.Code + '" class="find-resul\
t"><img src="' + val.Image + '"/><h2>' + val.Name + '</h2><span class="ui-li-as\
ide">£' + val.Price + '</span></a></li>'
				ul.html html
				ul.listview 'refresh'
				ul.trigger 'updatelayout'
	$('#find-listview').on 'click', '.find-result', ->
		mycode = $(this).data 'code'
		$('#find').one 'popupafterclose', ->
			basket.process_item mycode
			reader.start()
		$('#find').popup 'close'
	canmakevalidatecall = true
	validthings = []
	$('.input-validate').on 'keyup', ->
		value = $(this).val()
		item = $(this)
		if ($(this).data 'validate') is 'username'
			action = 'validate-username'
		else if ($(this).data 'validate') is 'password'
			action = 'validate-password'
		else
			return
		if canmakevalidatecall
			canmakevalidatecall = false
			apiret = (dat) ->
				if dat.sentinput is item.val()
					id = item.data 'validate'
					if dat.valid is true
						if id not in validthings
							validthings.push id
						item.parent().removeClass 'red-\
icon ui-icon-delete'
						item.parent().addClass 'green-i\
con ui-icon-check'
					else
						validthings = rm validthings, id
						item.parent().removeClass 'gree\
n-icon ui-icon-check'
						item.parent().addClass 'red-ico\
n ui-icon-delete'
					canmakevalidatecall = true
					a = 'username' in validthings
					b = 'password' in validthings
					c = 'email' in validthings
					i = $('#create-account-btn')
					if a and b and c
						i.removeClass 'ui-disabled'
					else
						i.addClass 'ui-disabled'
				else
					canmakevalidatecall = true
					basket.make_api_call action, false, {
						'input': item.val()
					}, (dat) ->
						apiret dat
			basket.make_api_call action, false, {
				'input': value
			}, (dat) ->
				apiret dat
	$('.input-same').on 'keyup', ->
		item = $(this)
		value = item.val()
		id = item.data 'same'
		comp = $('#' + id)
		comp.off('keyup').on 'keyup', ->
			ov = item.val()
			if $(this).val() isnt ov or not (/@/.test ov)
				validthings = rm validthings, 'email'
				item.parent().removeClass 'green-icon ui-icon-c\
heck'
				item.parent().addClass 'red-icon ui-icon-delete'
			else
				validthings.push 'email'
				item.parent().removeClass 'red-icon ui-icon-del\
ete'
				item.parent().addClass 'green-icon ui-icon-chec\
k'
			a = 'username' in validthings
			b = 'password' in validthings
			c = 'email' in validthings
			if a and b and c
				$('#create-account-btn').removeClass 'ui-disabl\
ed'
			else
				$('#create-account-btn').addClass 'ui-disabled'
		sameval = comp.val()
		if value isnt sameval or not (/@/.test value)
			validthings = rm validthings, 'email'
			$(this).parent().removeClass 'green-icon ui-icon-check'
			$(this).parent().addClass 'red-icon ui-icon-delete'
		else
			validthings.push 'email'
			$(this).parent().removeClass 'red-icon ui-icon-delete'
			$(this).parent().addClass 'green-icon ui-icon-check'
		a = 'username' in validthings
		b = 'password' in validthings
		c = 'email' in validthings
		if a and b and c
			$('#create-account-btn').removeClass 'ui-disabled'
		else
			$('#create-account-btn').addClass 'ui-disabled'
	$('#logout-btn').click ->
		if basket.loggedin
			basket.make_api_call 'logout', true, {}, (dat) ->
				$('#menu').panel 'close'
		else
			$('#logout-btn').addClass 'ui-disabled'
			$('#menu').panel 'close'
	$('#previous-transactions-btn').click ->
		$('#transactions-diag').one 'popupbeforeposition', ->
			$('#previous-transactions').collapsible 'expand'
			$('#saved-transactions').collapsible 'collapse'
		basket.make_api_call 'get-transactions', true, {}, (dat) ->
			basket.populate_transactions dat.baskets, dat.transactions, ->
				popup_open $('#transactions-diag')
	$('#saved-transactions-btn').click ->
		$('#transactions-diag').one 'popupbeforeposition', ->
			$('#saved-transactions').collapsible 'expand'
			$('#previous-transactions').collapsible 'collapse'
		basket.make_api_call 'get-transactions', true, {}, (dat) ->
			basket.populate_transactions dat.baskets, dat.transactions, ->
				popup_open $('#transactions-diag')
	$('#cancel-delete-transaction-btn').click ->
		$('#delete-transaction-diag').one 'popupafterclose', ->
			popup_open $('#transactions-diag')
		$('#delete-transaction-diag').popup 'close'
	$('#cancel-load-transaction-btn').click ->
		$('#load-transaction-diag').one 'popupafterclose', ->
			popup_open $('#transactions-diag')
		$('#load-transaction-diag').popup 'close'
	$('#saved-transactions-ul').on 'click', '.load-transaction-btn', ->
		dataid = $(this).data 'transactionid'
		$('#confirm-load-transaction-btn').off().one 'click', ->
			basket.make_api_call 'load-basket', true, {
				id: dataid
			}, (dat) ->
				basket.reprocess_basket()
				$('#info-diag-text').html 'Successfully loaded b\
asket'
				$('#load-transaction-diag').one 'popupafterclos\
e'
				, ->
					popup_open $('#info-diag')
				$('#load-transaction-diag').popup 'close'
		$('#transactions-diag').one 'popupafterclose', ->
			popup_open $('#load-transaction-diag')
		$('#transactions-diag').popup 'close'
	$('#saved-transactions-ul').on 'click', '.delete-transaction-btn', ->
		dataid = $(this).data 'transactionid'
		$('#confirm-delete-transaction-btn').off().one 'click', ->
			basket.make_api_call 'delete-basket', true, {
				id: dataid
			}, (dat) ->
				$('#info-diag-text').html 'Successfully deleted b\
asket'
				$('#info-diag').one 'popupafterclose', ->
					b = dat.baskets
					t = dat.transactions
					basket.populate_transactions b, t, ->
						popup_open $('#transactions-dia\
g')
				$('#delete-transaction-diag').one 'popupaftercl\
ose'
				, ->
					popup_open $('#info-diag')
				$('#delete-transaction-diag').popup 'close'
		$('#transactions-diag').one 'popupafterclose', ->
			popup_open $('#delete-transaction-diag')
		$('#transactions-diag').popup 'close'
	$('#basket-name-input').on 'keyup', ->
		if ($(this).val().replace /^\s+|\s+$/g, "") is ""
			$('#submit-basket-name-btn').addClass 'ui-disabled'
		else
			$('#submit-basket-name-btn').removeClass 'ui-disabled'
	$('#submit-basket-name-btn').click ->
		realval = $('#basket-name-input').val().replace /^\s+|\s+$/g, ""
		if realval is ""
			$(this).addClass 'ui-disabled'
		else
			if basket.loggedin
				basket.make_api_call 'save-basket', true, {
					'name': realval
				}, (dat) ->
					i = $('#save-transaction-diag')
					i.one 'popupafterclose', ->
						$('#info-diag-text').html 'Bask\
et "' + realval + '" saved successfully'
						popup_open $('#info-diag')
					i.popup 'close'
	$('#save-transaction-btn').click ->
		if not basket.loggedin
			popup_open $('#login-diag')
		else if (Object.keys basket.data).length is 0
			$('#info-diag-text').html 'Your basket is empty. Please\
 add items to the basket before continuing.'
			popup_open $('#info-diag')
		else
			popup_open $('#save-transaction-diag')
popup_open = (item) ->
	item.popup 'open', {transition: 'pop', positionTo: 'window'}
alter_scan_offset  = ->
	new_height = $(window).height()
	new_height -= $('#scan').offset().top
	new_height -= $('#find-footer').outerHeight()
	new_height -= $('#tab-navbar').outerHeight()
	$('#interactive').css 'height', new_height
